import React from "react";
import { Layout } from "../../../components/Layout";
import { Seo } from "../../../components/Seo";
import {
  ServicesBenefit,
  ServicesBenefitsList,
  ServicesBenefitsWrapper,
} from "../../../components/BenefitList";
import { Link } from "../../../components/Link";
import { Section, SectionTitle } from "../../../components/Section";
import {
  PageHero,
  PageHeroContact,
  PageHeroWrap,
} from "../../../components/PageHero";
import { MdxBlogPostsSection } from "../../../components/Blog/MdxBlogPosts";
import { MdxCaseStudiesSection } from "../../../components/CaseStudies/MdxCaseStudies";
import { MdxTestimonialsSection } from "../../../components/Testimonials/MdxTestimonials";

import requirementsImg from "../../../images/howItWorks/requirements.svg";
import discoveryImg from "../../../images/howItWorks/discovery.svg";
import developmentImg from "../../../images/howItWorks/development.svg";
import launchImg from "../../../images/howItWorks/launch.svg";
import supportImg from "../../../images/howItWorks/support.svg";
import controlImg from "../../../images/howItWorks/control.svg";

import teamIcon from "../../../images/benefits/team-icon.svg";
import designIcon from "../../../images/benefits/design-icon.svg";
import complianceIcon from "../../../images/benefits/compliance-icon.svg";
import experienceIcon from "../../../images/benefits/experience-icon.svg";
import expertiseIcon from "../../../images/benefits/expertise-icon.svg";
import publishingIcon from "../../../images/benefits/publishing-icon.svg";
import developmentIcon from "../../../images/benefits/development-icon.svg";
import ogImage from "./og_monday.png";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  getContactUrl,
  getSearchInUpdatesInstallationUrl,
} from "../../../utils/urls";
import {
  Card,
  CardBody,
  CardImage,
  CardList,
  CardTitle,
} from "../../../components/Card";
import { Image } from "../../../components/Image";
import * as styles from "./index.module.scss";
import mondayAppsImg from "../../../images/undraw/monday-apps.svg";
import {
  HeaderContainer,
  Text,
  Title,
} from "../../../components/Services/HeaderContainer";
import { Button } from "../../../components/Button";
import {
  HowItWorksItem,
  HowItWorksWrapper,
} from "../../../components/HowItWorksItem";

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      cases: allMdx(
        filter: {
          fields: { collection: { eq: "cases" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "monday" } }
        }
        sort: { fields: frontmatter___priority }
      ) {
        ...CaseStudiesData
      }

      testimonials: allMdx(
        filter: {
          fields: { collection: { eq: "testimonials" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "monday" } }
        }
        sort: { fields: frontmatter___priority }
        limit: 5
      ) {
        ...TestimonialsData
      }

      blogPosts: allMdx(
        filter: {
          fields: { collection: { eq: "blog" } }
          frontmatter: { priority: { gt: 0 }, tags: { eq: "monday" } }
        }
        sort: { fields: frontmatter___priority }
        limit: 5
      ) {
        ...BlogPostsData
      }
    }
  `);

  return (
    <Layout>
      <Seo
        ogFullTitle
        title="monday.com Apps Development"
        description="Extend monday.com platform to your needs with custom UI features, integration and automation apps"
        ogImage={ogImage}
        maxImagePreview="large"
      />
      <div className={styles.outer}>
        <PageHeroWrap className={styles.hero_outer}>
          <HeaderContainer
            className={styles.container}
            title={
              <Title>
                <span className={styles.highlight}>monday.com</span> Apps
                Development
              </Title>
            }
            image={<Image image={mondayAppsImg} alt="Monday Apps" />}
          />
        </PageHeroWrap>
      </div>

      <PageHeroWrap className={styles.monday_platform}>
        <HeaderContainer
          title={
            <Title>
              <div className={styles.monday_platform_title}>
                Get the most out of monday.com platform
              </div>
            </Title>
          }
          className={styles.monday_info}
          image={
            <iframe
              src="https://www.youtube.com/embed/nfb7wc6S4m8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          }
          text={
            <Text>
              <ul className={styles.list}>
                <li>
                  Automate your workflows and get the most benefits from the
                  monday.com platform
                </li>
                <li>
                  Exchange data between your existing applications and
                  monday.com with integration recipes.
                </li>
                <li>
                  Add complex logic to your boards with tailor-made triggers and
                  actions.
                </li>
              </ul>
            </Text>
          }
          btn={<Button to={getContactUrl()}>Contact Us</Button>}
        />
      </PageHeroWrap>

      <ServicesBenefitsWrapper>
        <SectionTitle className={styles.benefitTitle}>
          Why LeanyLabs?
        </SectionTitle>
        <ServicesBenefitsList>
          <ServicesBenefit
            image={<Image image={teamIcon} alt="Dedicated Team" />}
            title="Dedicated Team"
          >
            We have a dedicated team specializing on monday.com apps development
          </ServicesBenefit>
          <ServicesBenefit
            image={
              <Image image={developmentIcon} alt="End-to-End Development" />
            }
            title="End-to-End Development"
          >
            We take care of all technical aspects, like architecture,
            development, deployment and monitoring of the product
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={designIcon} alt="Product Design" />}
            title="Product Design"
          >
            We assist with product design as our main goal is to solve your
            business problem
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={experienceIcon} alt="Experience" />}
            title="Experience"
          >
            We've built more than 50 different monday.com apps over the course
            of 2 years
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={expertiseIcon} alt="Expertise" />}
            title="Expertise"
          >
            We use monday.com on a daily basis and know its API inside out
          </ServicesBenefit>
          <ServicesBenefit
            image={
              <Image image={publishingIcon} alt="Assistance with Publishing" />
            }
            title="Assistance with Publishing"
          >
            We have experience publishing apps to the monday.com marketplace
          </ServicesBenefit>
          <ServicesBenefit
            image={<Image image={complianceIcon} alt="GDPR Compliance" />}
            title="GDPR Compliance"
          >
            We are GDPR compliant and keep your data safe
          </ServicesBenefit>
        </ServicesBenefitsList>
      </ServicesBenefitsWrapper>

      <Section>
        <SectionTitle>Our Apps</SectionTitle>
        <CardList>
          <Card>
            <CardImage>
              <Link to={getSearchInUpdatesInstallationUrl()}>
                <StaticImage
                  src="./search_in_updates.png"
                  alt="Search In Updates"
                  quality={90}
                  formats={["auto", "webp"]}
                />
              </Link>
            </CardImage>
            <CardTitle>
              <Link to={getSearchInUpdatesInstallationUrl()}>
                Search In Updates
              </Link>
            </CardTitle>
            <CardBody>
              Looking through dozens of updates can be really daunting because
              there's no search functionality built-in. "Search in Updates"
              solves this problem by implementing full text search for item's
              updates. It also conveniently highlights all the matches.
            </CardBody>
          </Card>
        </CardList>
      </Section>

      <HowItWorksWrapper>
        <SectionTitle>How it Works</SectionTitle>
        <HowItWorksItem
          title="Discovery"
          image={<Image image={discoveryImg} alt="Discovery" />}
          // image={<Image image={discoveryImg} alt="Discovery" />}
        >
          We get to know each other, listen to your needs, fully understand the
          problem and provide you with the approximate estimate.
        </HowItWorksItem>
        <HowItWorksItem
          title="Requirements Clarification and Product Design"
          image={
            <Image
              image={requirementsImg}
              // image={requirementsImg}
              alt="Requirements clarification and product design"
            />
          }
        >
          Elaborate on the possible solution. Afterwards, we propose detailed
          recipes, UI/UX, project scope, and delivery timeline.
        </HowItWorksItem>
        <HowItWorksItem
          title="Development"
          image={<Image image={developmentImg} alt="Development" />}
        >
          We will build your application in short cycles, providing frequent
          progress updates and demos during the whole process.
        </HowItWorksItem>
        <HowItWorksItem
          title="Quality Control"
          image={<Image image={controlImg} alt="Quality Control" />}
        >
          We test the solution manually and with automated tests where
          appropriate. We try to emulate your environment as closely as possible
          using custom boards and do final testing of the app on our monday.com
          account.
        </HowItWorksItem>
        <HowItWorksItem
          title="Launch"
          image={<Image image={launchImg} alt="Launch" />}
        >
          Once you're happy with the functionality and everything has been
          tested, we will deploy the application to your production environment
          and install it into your monday.com account.
        </HowItWorksItem>
        <HowItWorksItem
          title="Monitoring and Support"
          image={<Image image={supportImg} alt="Monitoring and Support" />}
        >
          We have a proven process of monitoring every monday.com app we've
          developed that guarantees we'll be aware of issues before the actual
          users, and fixing it will be our top priority.
        </HowItWorksItem>
      </HowItWorksWrapper>

      <MdxTestimonialsSection testimonialsData={data.testimonials} />
      <MdxCaseStudiesSection caseStudiesData={data.cases} />
      <MdxBlogPostsSection blogPostsData={data.blogPosts} />
    </Layout>
  );
};

export default Page;
